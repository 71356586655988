<template>
  <div>
    <b-breadcrumb :items="breadcrumbs" />
    <EntityEdit
      :module="config.module"
      :entity="entity"
      :edit-mode="editMode"
      :extra-validation="extraValidation"
      :submit-callback="submitCallback"
      :extra-actions="extraActions"
    >
      <slot />
    </EntityEdit>
  </div>
</template>

<script>
import { BBreadcrumb } from 'bootstrap-vue'
import EntityEdit from '@/layouts/entity/EntityEdit.vue'

export default {
  components: {
    BBreadcrumb,
    EntityEdit,
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        store: null,
        module: 'example',
        endpoint: 'example',
        route: 'example',
        title: {
          single: 'Example',
          plural: 'Examples',
        },
      }),
    },
    entity: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    extraValidation: {
      type: Function,
      default: null,
      required: false,
    },
    submitCallback: {
      type: Function,
      default: null,
      required: false,
    },
    extraActions: {
      type: Array,
      default: null,
      required: false,
    },
    storeEntity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    breadcrumbs() {
      let activeBreadcrumb = ''

      if (!this.editMode) {
        activeBreadcrumb = this.$t('View')
      } else if (this.entity.id) {
        activeBreadcrumb = this.$t('Edit')
      } else {
        activeBreadcrumb = this.$t('Add')
      }

      return [
        {
          text: 'Dashboard',
          to: { name: 'dashboard' },
        },
        {
          text: this.config.title.plural,
          to: { name: `${this.config.route}-list` },
        },
        {
          text: `${activeBreadcrumb} ${this.config.title.single}`,
          active: true,
        },
      ]
    },
  },
  created() {
    if (!this.$store.hasModule(this.config.module)) {
      this.$store.registerModule(this.config.module, this.config.store)
      this.$store.commit(`${this.config.module}/setConfig`, this.config)
    }

    this.fetchEntity()
    this.$watch('id', () => {
      this.fetchEntity()
    })
  },
  beforeDestroy() {
    if (this.$store.hasModule(this.config.module)) {
      this.$store.unregisterModule(this.config.module)
    }
  },
  methods: {
    async fetchEntity() {
      let entityID = this.$route.params.id ? this.$route.params.id : null
      let isDuplicate = false
      let keepOrigin = false

      if (this.$route.params.duplicate) {
        entityID = this.$route.params.duplicate
        isDuplicate = true
        keepOrigin = this.$route.params.keepOrigin
      }

      if (entityID) {
        await this.$store
          .dispatch(`${this.config.endpoint}/getEntity`, {
            id: entityID,
          })
          .then(res => {
            const entity = res.data

            if (isDuplicate) {
              if (keepOrigin) {
                entity.originId = entity.id
              }
              delete entity.id
              if (entity.participant) {
                delete entity.participant.id
              }
            }
            if (this.storeEntity) {
              this.$store.commit('app-common/selectedEntity', entity)
            }

            this.$emit('update:entity', entity)
          })
      }

      this.$emit('update:loaded', true)
    },
  },
}
</script>
