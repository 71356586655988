<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :extra-validation="extraValidation"
    :submit-callback="submitCallback"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon icon="UsersIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Information') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="formFields"
            allow-autofocus
          />
          <b-form-checkbox v-model="grantAllRights">
            {{ $t('Grant All Rights') }}
          </b-form-checkbox>
          <hr />
          <b-tabs v-if="rightsLoaded" pills vertical>
            <b-tab
              v-for="(rightGroup, index) in entity.rights"
              :key="rightGroup.title + index"
              :title="$t(rightGroup.title)"
              :active="index == 0"
            >
              <user-permissions-card
                :rights-group="rightGroup"
                :view-mode="false"
              />
              <b-form-checkbox
                v-model="grantGroupRights"
                :value="rightGroup.title"
              >
                {{ $t('Grant Group Rights') }}
              </b-form-checkbox>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { translationUtils } from '@core/mixins/ui/translations'
import { formUtils } from '@core/mixins/ui/forms'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import UserPermissionsCard from '../../users/UserPermissionsCard.vue'
import storeModule from '../userGroupStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    UserPermissionsCard,
  },
  mixins: [translationUtils, formUtils],
  data() {
    return {
      entity: {
        rights: [],
      },
      entityLoaded: false,
      rightsLoaded: false,
      grantAllRights: false,
      grantGroupRights: [],
      roleOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'usergroup',
        endpoint: 'usergroup',
        route: 'user-groups',
        title: {
          single: this.$t('User Group'),
          plural: this.$t('User Groups'),
        },
      }
    },

    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'subtitle',
          type: 'text',
          label: this.$t('subtitle'),
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('Enabled'),
          colSize: 1,
        },
        {
          id: 'role',
          object: true,
          type: 'select',
          label: this.$t('role'),
          options: this.roleOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'description',
          type: 'quill',
          label: this.$t('description'),
          required: true,
          colSize: 12,
        },
      ]
    },
  },
  mounted() {
    this.$watch('entityLoaded', this.fetchUserGroupRights)
    this.$watch('grantAllRights', this.allRightsGranted)
    this.$watch('grantGroupRights', this.groupRightsGranted)
    this.$store.dispatch('app-common/fetchRoles').then((response) => {
      this.roleOptions = this.translateOptions(response.data)
    })
  },
  methods: {
    extraValidation() {
      this.entity.userGroupRights = []
      this.entity.rights.forEach((group) => {
        group.subgroups.forEach((subgroup) => {
          const rights = Object.keys(subgroup)
          rights.forEach((right) => {
            if (
              right !== 'title' &&
              right !== 'canAssign' &&
              subgroup[right].value
            ) {
              this.entity.userGroupRights.push({
                right: {
                  id: subgroup[right].id,
                  rightsGroup: group.title,
                  rightsSubgroup: subgroup.title,
                  title: right,
                },
                // canAssign: subgroup.canAssign.value,
              })
            }
          })
        })
      })
      return ''
    },
    submitCallback() {
      this.$router.push({ name: 'user-groups-list' })
    },
    allRightsGranted() {
      if (this.grantAllRights) {
        this.bulkAssignRights(null)
      }
    },
    groupRightsGranted() {
      if (this.grantGroupRights.length > 0) {
        this.bulkAssignRights(this.grantGroupRights)
      }
    },
    bulkAssignRights(groups) {
      this.entity.rights.forEach((group, gindex) => {
        if (groups != null && !groups.includes(group.title)) {
          return
        }
        group.subgroups.forEach((subgroup, sindex) => {
          const rights = Object.keys(subgroup)
          rights.forEach((right) => {
            if (right !== 'title') {
              this.entity.rights[gindex].subgroups[sindex][right].value = true
            }
          })
        })
      })
    },
    fetchUserGroupRights() {
      if (this.entityLoaded) {
        this.$store
          .dispatch('app-common/fetchUserGroupRights', {
            id: this.entity.id,
          })
          .then((response) => {
            this.entity.rights = this.formatRightsForTable(response.data)
            this.rightsLoaded = true
          })
      }
    },
  },
}
</script>
