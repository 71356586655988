<template>
  <div>
    <validation-observer
      v-slot="{ invalid, validate }"
      ref="entityFormObserver"
    >
      <b-form
        class="p-2"
        autocomplete="off"
        @submit.prevent="validate().then(submit)"
      >
        <slot :validate="validate" />

        <div v-if="editMode" class="mb-3 text-right">
          <template v-for="extraAction in extraActions">
            <b-button
              v-if="extraActionShouldRender(extraAction)"
              :key="extraAction.title"
              :variant="extraAction.variant ? extraAction.variant : 'info'"
              :disabled="disableActions"
              :href="extraAction.href ? extraAction.href : null"
              class="mr-1"
              target="_blank"
              @click="handleExtraActionClick(extraAction)"
            >
              {{ $t(extraAction.title) }}
            </b-button>
          </template>

          <!-- Action Buttons -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-sm-1"
            type="submit"
            :disabled="disableActions || invalid"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    extraValidation: {
      type: Function,
      default: null,
      required: false,
    },
    submitCallback: {
      type: Function,
      default: null,
      required: false,
    },
    extraActions: {
      type: Array,
      default: null,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup(props) {
    const { refFormObserver, getValidationState } = formValidation(
      props.clearEntityData
    )

    return {
      refFormObserver,
      getValidationState,
    }
  },
  data() {
    return {
      customValidationMsg: '',
      disableActions: false,
    }
  },
  computed: {
    config() {
      return this.$store.getters[`${this.module}/config`]
    },
  },
  methods: {
    addEntity(data) {
      this.saveEntity('add', data)
    },
    updateEntity(data) {
      this.saveEntity('update', data)
    },
    saveEntity(saveType, data) {
      this.disableActions = true
      this.$store
        .dispatch(`${this.config.endpoint}/${saveType}Entity`, data)
        .then((response) => {
          this.$emit('entitySubmitted', data)
          this.handleEntitySave(response)
          this.disableActions = false
        })
        .catch((error) => {
          this.handleSaveError(error)
          this.disableActions = false
        })
    },
    showToast(toastProps) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: toastProps,
      })
    },
    handleEntitySave(response) {
      this.processEntitySave(
        response,
        this.$t('Entity Saved'),
        this.$t('Save completed successfully')
      )
      this.$store.commit('app-common/clearCache')
    },
    processEntitySave(response, msgTitle, msgText) {
      if (response.data) {
        this.$parent.$parent.entity = response.data
        this.showToast({
          title: msgTitle,
          icon: 'CheckIcon',
          variant: 'success',
          text: msgText,
        })
        this.$nextTick(() => {
          this.$refs.entityFormObserver.reset()
          if (this.submitCallback) {
            this.submitCallback()
          }
        })
      } else {
        const error = { message: this.$t('An unexpected error occurred') }
        this.handleSaveError(error)
      }
    },
    handleSaveError(error) {
      this.showToast({
        title: this.$t('Save Failure'),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
        text: this.$t(error.response.data.message),
      })
    },
    async submit() {
      let isValid = await this.$refs.entityFormObserver.validate()
      if (this.extraValidation) {
        this.customValidationMsg = this.extraValidation()
        isValid = this.customValidationMsg.length === 0
        if (!isValid) {
          this.showToast({
            title: this.$t('Save Failure'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: this.customValidationMsg,
          })
        }
      }
      if (isValid) {
        if (this.entity.id) {
          this.updateEntity(this.entity)
        } else {
          this.addEntity(this.entity)
        }
      }
    },

    extraActionShouldRender(action) {
      if (
        this.entity.id &&
        // If no authority validation is given or the validation passes continue
        // eslint-disable-next-line no-prototype-builtins
        (!action.hasOwnProperty('authorityValidation') ||
          action.authorityValidation) &&
        // If no render condition is given or the key matches the desired value continue
        (!action.renderCondition ||
          (action.renderCondition.exists &&
            this.entity[action.renderCondition.key] > 0) ||
          this.entity[action.renderCondition.key] ===
            action.renderCondition.value)
      ) {
        return true
      }

      return false
    },

    async handleExtraActionClick(action) {
      if (!action.href) {
        this.disableActions = true
        const response = await axios.get(
          `${this.config.endpoint}/${action.endpoint}/${this.entity.id}`
        )

        this.disableActions = false
        this.processEntitySave(
          response,
          this.$t('Action completed'),
          this.$t('Action completed successfully')
        )
      }
    },
  },
}
</script>
